
import { filter, propEq } from "ramda";

const getSizes = filter(propEq("name", "Size"));

export default {
  props: {
    options: { type: Array, default: () => [] },
    handle: { type: String, default: "" },
  },

  computed: {
    sizeOptions() {
      return getSizes(this.options);
    },
    hasSizes() {
      return this.sizeOptions.length != 0;
    },
    sizesValuesCount() {
      return this.sizeOptions[0]?.values.length;
    },
    sizesLabel() {
      return this.sizesValuesCount
        ? `${this.sizesValuesCount} ${
            this.sizesValuesCount == 1 ? "size" : "sizes"
          }`
        : null;
    },
  },
};
