import { render, staticRenderFns } from "./AppProductTilePrice.vue?vue&type=template&id=2efe03d6&"
import script from "./AppProductTilePrice.vue?vue&type=script&lang=js&"
export * from "./AppProductTilePrice.vue?vue&type=script&lang=js&"
import style0 from "./AppProductTilePrice.vue?vue&type=style&index=0&id=2efe03d6&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSaleBody: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppSaleBody.vue').default})
