import { render, staticRenderFns } from "./ProductTileImagesLegacy.vue?vue&type=template&id=6192d15e&"
import script from "./ProductTileImagesLegacy.vue?vue&type=script&lang=js&"
export * from "./ProductTileImagesLegacy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductTileImageCarousel: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileImageCarousel.vue').default})
