
import { gql } from "graphql-tag";

const IMAGES_QUERY = gql`
  query images($ids: [ID!]!) {
    images: nodes(ids: $ids) {
      ... on MediaImage {
        image {
          id
          altText
          src: url
          w_288_h_288: url(
            transform: {
              maxWidth: 288
              maxHeight: 288
              preferredContentType: PNG
            }
          )
          w_288_h_288_webp: url(
            transform: {
              maxWidth: 288
              maxHeight: 288
              preferredContentType: WEBP
            }
          )
        }
      }
    }
  }
`;

export default {
  props: { imagesProps: { type: Object, default: () => {} } },

  data() {
    return { images: [this.imageProps?.mainImage].filter(Boolean) };
  },

  async fetch() {
    if (this.imageIds.length < 1) {
      return;
    }

    const areImagesGIDs = this.imageIds.find(image =>
      image.includes("/MediaImage/")
    );

    if (areImagesGIDs) {
      const { data } = await this.$getShopifyData({
        query: IMAGES_QUERY,
        variables: { ids: this.imageIds },
      });

      const images = (data.images ?? [])
        .filter(Boolean)
        .map(({ image }) => image);
      this.images = images.length === 0 ? [this.imageProps?.mainImage] : images;
    }
  },

  fetchOnServer: false,

  computed: {
    imageIds() {
      return this.imageProps?.metafields?.images ?? [];
    },
    carouselProps() {
      return {
        ...this.imageProps,
        images: this.images,
      };
    },
  },
};
