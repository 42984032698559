
import { getPrismicDate } from "~/utils/prismic-utils";

export default {
  props: {
    priorityCampaign: { type: Object, default: () => {} },
    maxDiscount: { type: [Number, String], default: 0 },
    compareAtPrice: { type: [Number, String], default: 0 },
    isPlp: { type: Boolean, default: false },
  },

  computed: {
    saleBody() {
      return this.isPlp
        ? this.priorityCampaign?.plpSaleBody
        : this.priorityCampaign?.pdpSaleBody;
    },
    campaignEndDate() {
      const campaignEndTime = getPrismicDate(this.priorityCampaign?.endTime);
      if (!campaignEndTime) {
        return "";
      }

      const campaignEndDate = `0${campaignEndTime.getDate()}`.slice(-2);
      const campaignEndMonth = `0${campaignEndTime.getMonth() + 1}`.slice(-2);
      return `${campaignEndDate}/${campaignEndMonth}/${campaignEndTime.getFullYear()}`;
    },
    saleBodyHtml() {
      let html = this.saleBody ? this.$prismic.asHtml(this.saleBody) : "";
      if (this.campaignEndDate) {
        html = html.replace(/__date__/g, this.campaignEndDate);
      }
      if (this.compareAtPrice) {
        const price = this.maxDiscount
          ? (Math.ceil(this.compareAtPrice) * Number(this.maxDiscount)) / 100
          : this.compareAtPrice;

        html = html.replace(
          /__price__/g,
          this.$options.filters.formatCurrency(price)
        );
      }
      if (this.maxDiscount) {
        html = html.replace(/__percentage__/g, this.maxDiscount);
      }
      return html;
    },
  },
};
