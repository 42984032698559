
import { parseGid } from "@shopify/admin-graphql-api-utilities";

export default {
  props: {
    // colorSwatches will be like
    // { name: "Natural", hex_code: "#d8b58f", variant_id: "gid://shopify/ProductVariant/42750069571628"}[]
    colorSwatches: { type: Array, default: () => [] },
    handle: { type: String, default: "" },
  },

  data() {
    return { selectedColor: this.colorSwatches[0]?.name, MAX_COLORS: 3 };
  },

  computed: {
    colors() {
      return this.colorSwatches
        .slice(0, this.MAX_COLORS)
        .map(color => ({ ...color, variant_id: parseGid(color.variant_id) }));
    },
  },
};
